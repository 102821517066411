$asset-detail-aside-icon-size: 3.2rem;

.asset-detail-aside {
  @include spacing(s, all, margin);
  @include font-asset-detail-aside-error;

  &__header-area {
    @include flex-container($justify-content: space-between);
    margin: $grid-gutter-size-s 0;
  }

  &__overflow {
    @include flex-item();
    max-width: $page-template-asset-detail-aside-width - $asset-detail-aside-icon-size - (2 * $grid-gutter-size-s) - 2.4rem;
  }

  &__header {
    display: block;
    word-break: break-word;
  }

  &__link {
    @include flex-item();

    &:hover,
    &:focus {
      &:before {
        background-color: $color-asset-detail-aside-icon-hover;
      }
    }

    &:before {
      background-color: $color-asset-detail-aside-icon;
      content: '';
      display: block;
      height: $asset-detail-aside-icon-size;
      mask: url('../img/icons/icon-asset-aside-enlarge.svg') no-repeat 50% 50%;
      mask-size: cover;
      width: $asset-detail-aside-icon-size;
    }
  }

  &__buttons {
    margin: $grid-gutter-size auto;
    text-align: center;
    width: 100%;
  }
}
