$menu-nav-link-border-style: 0.1rem solid $color-menu-nav-link-border;

.sidebar-menu {
  @include spacing(s, all, margin);
  @include font-sidebar-menu-error;

  &__list {
    list-style-type: none;

    & .sidebar-menu__list {
      & .sidebar-menu__list {
        margin-left: 2rem;
      }
    }
  }

  &__list > &__list-item {
    border-top: $menu-nav-link-border-style;
    padding: 1rem 0;

    & .sidebar-menu__list-item {
      & > .menu-nav-link {
        & > .menu-nav-link__text {
          margin-left: 1rem;
        }

        &:hover,
        &:focus {
          &:before {
            background-color: $color-sidebar-menu-list-item-hover;
          }
        }

        &:before {
          //BAStodo: commented out for the time being so we don't get any problems with our linter bc of vendor prefixes
          // -webkit-mask: url('../img/icons/expand.svg') no-repeat 50% 50%;
          // -webkit-mask-size: cover;
          background-color: $color-sidebar-menu-list-item;
          content: '';
          display: block;
          height: 16px;
          mask: url('../img/icons/expand.svg') no-repeat 50% 50%;
          mask-size: cover;
          width: 16px;
        }
      }

      &--has-no-children > .menu-nav-link {
        & > .menu-nav-link__text {
          margin-left: 2.5rem;
        }

        &:before {
          display: none;
        }

      }

      &--in-active-tree > .menu-nav-link {
        &:before {
          //BAStodo: commented out for the time being so we don't get any problems with our linter bc of vendor prefixes
          // -webkit-mask: url('../img/icons/expanded.svg') no-repeat 50% 50%;
          mask: url('../img/icons/expanded.svg') no-repeat 50% 50%;
        }
      }
      border-top: 0;
      padding: 0;
    }
  }

  &__list-item {
    @include font-sidebar-menu-nav-link-main;
    color: #808080;
    display: list-item;

    & .sidebar-menu__list-item {
      @include font-sidebar-menu-nav-link-sub;
      margin: .5rem 0;
    }

    &--hide {
      display: none;
    }

    &--in-active-tree {
      color: #000;

    }
  }
}
