.latest-assets {
  border-top: .1rem solid $color-home-page-asset-tiles-border;

  &__headline {
    @include font-home-page-asset-tiles-headline;
    @include spacing(m, top, padding);
  }

  .asset-tiles__list-element {
    min-width: 28.8rem;
    width: 100%;
    // these breakpoints are based on how many tiles with a min width of 282px and 8px padding fit in one row.
    // they have been calculated and tested individually, therefore - if you have to adjust this in the future:
    // do not attempt to switch to our general breakpoint variables, but calculate and test instead, please

    @include breakpoint(592px) {
      width: calc(100% / 2);
    }

    @include breakpoint(880px) {
      width: calc(100% / 3);
    }

    @include breakpoint(1168px) {
      width: calc(100% / 4);
    }

    @include breakpoint(1200px) {
      width: calc(100% / 3);
    }

    @include breakpoint(1474px) {
      width: calc(100% / 4);
    }

    @include breakpoint(1762px) {
      width: calc(100% / 5);
    }
  }
}
