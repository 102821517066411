.menu-button {
  background-color: $color-icon-button-background;
  border: 0;
  border-radius: 100%;
  color: transparent;
  cursor: pointer;
  display: inline-block;
  height: 3.2rem;
  width: 3.2rem;

  &--open {
    &:before {
      mask: url('../img/icons/icon-menu-closed.svg') no-repeat 50% 50% / 100% 100%;
    }
  }

  &--close {
    &:before {
      mask: url('../img/icons/icon-menu-opened.svg') no-repeat 50% 50% / 100% 100%;
    }
  }

  &:before {
    background-color: $color-icon-button-mask;
    content: '';
    display: block;
    height: 3.2rem;
    width: 3.2rem;
  }

  @include breakpoint($breakpoint-l) {
    display: none;
  }
}
