@mixin text-body-1 {
  @include font-primary;
  font-size: 1.8rem;
  line-height: 1.4;
}

@mixin text-body-2 {
  @include font-primary;
  font-size: 1.6rem;
  line-height: 1.4;
}

@mixin text-body-3 {
  @include font-primary;
  font-size: 1.4rem;
  line-height: 1.4;
}

@mixin text-input-field {
  @include font-primary;
  font-size: 2.1rem;
  line-height: 1.52;
}
