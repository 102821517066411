.asset-tiles-empty {
  &__text {
    @include font-asset-tiles-empty-text;
    @include spacing(s, bottom, margin);
    color: $color-asset-tiles-empty-text;
  }

  &__description {
    @include font-asset-tiles-empty-text;
    align-items: center;
    color: $color-asset-tiles-empty-text;
    display: flex;
  }

  &__icon {
    @include icon-button();
    @include icon-button-mask($mask-url: '../img/icons/addToAssetCart.svg');

    &:hover {
      cursor: default;

      &:before {
        background-color: $color-icon-button-mask;
      }
    }
  }
}
