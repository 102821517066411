.icon-buttons {
  &__button-label {
    @include font-icon-buttons-label;
    color: $color-icon-buttons-label;

    &:nth-of-type(1) {
      @include breakpoint($breakpoint-m) {
        margin-right: 2rem;
      }
    }
  }

  &__button {
    @include icon-button();

    &--download {
      @include icon-button-mask($mask-url: '../img/icons/download.svg');
    }

    &--download-all {
      @include icon-button-mask($mask-url: '../img/icons/download.svg');
    }

    &--add {
      @include icon-button-mask($mask-url: '../img/icons/addToAssetCart.svg');
    }

    &--remove {
      @include icon-button-mask($mask-url: '../img/icons/removeFromAssetCart.svg');
    }

    &--delete-all {
      @include icon-button-mask($mask-url: '../img/icons/removeAllFromAssetCart.svg');
    }
  }
}
