.asset-cart-page-introduction {
  @include spacing(m, bottom, margin);
  @include spacing(s, bottom, padding);
  @include flex-container($flex-direction: column,$align-items: center);

  @include breakpoint($breakpoint-m) {
    border-bottom: 1px solid $color-asset-cart-page-introduction-border;
    flex-direction: row;
  }

  &__headline {
    @include font-asset-cart-headline;
    display: inline-block;
    width: 100%;

    @include breakpoint($breakpoint-m) {
      width: auto;
    }
  }

  &__asset-count {
    color: $color-asset-cart-page-introduction-asset-count;
    display: inline-block;
    font-weight: normal;
    margin-left: 0.5rem;
    margin-right: 2.4rem;
  }

  &__button-wrap,
  &__buttons {
    @include flex-container ($align-items: center);
    margin-bottom: 0.8rem;
    width: 100%;

    @include breakpoint($breakpoint-m) {
      height: 100%;
      margin-bottom: 0;
      width: auto;
    }
  }

  .icon-button__label {
    &:nth-of-type(1) {
      @include breakpoint($breakpoint-m) {
        margin-right: 2rem;
      }
    }
  }
}
