.logo {
  @include flex-container($align-items: center);
  z-index: 1;

  @include breakpoint($breakpoint-l) {
    @include flex-container($flex-direction: column, $justify-content: center, $align-items: center);
  }

  &__image {
    height: auto;
    margin-right: .6rem;
    width: 11.1rem;

    @include breakpoint($breakpoint-l) {
      height: 2.9rem;
      margin-bottom: .6rem;
      margin-right: 0;
      width: 14.6rem;
    }
  }

  &__text {
    background: url('../img/logos/mediathek-logo.svg') no-repeat center center / contain;
    color: transparent;
    display: block;
    height: 2.5rem;
    width: 10.8rem;

    @include breakpoint($breakpoint-l) {
      height: 3.5rem;
      width: 14.7rem;
    }
  }
}
