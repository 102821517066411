.tracking-notice {
  @include font-tracking-notice-text;
  background-color: $color-tracking-notice-background;
  bottom: 0;
  display: none;
  left: 0;
  position: fixed;
  width: 100%;
  z-index: 900;

  &__inner-wrapper {
    @include flex-container($flex-wrap: wrap, $justify-content: space-between, $align-items: center);
    color: $color-tracking-notice-text;
    margin: 10px;

    @include breakpoint($breakpoint-m) {
      align-items: center;
      flex-wrap: nowrap;
      margin: 20px;
    }
  }

  &__text {
    margin-bottom: 18px;
    width: 100%;

    @include breakpoint($breakpoint-m) {
      flex: 1;
      margin-bottom: 0;
      margin-right: 40px;
      width: auto;
    }
  }

  &__link-container {
    color: $color-tracking-notice-text-link;
    display: inline-block;
    font-weight: 700;
  }

  &__buttons {
    @include flex-container($flex-wrap: wrap, $justify-content: center, $align-items: center);
    width: 100%;

    @include breakpoint($breakpoint-m) {
      flex-wrap: nowrap;
      width: 169px;
      width: auto;
    }
  }

  &__button {
    @include button-wide;
    border-radius: 25px;
    margin: 5px;

    &--primary {
      background: $color-tracking-notice-accept;
      color: $color-tracking-notice-accept-text;

      &:hover {
        background: $color-tracking-notice-accept-hover;
      }

      &:active,
      &:focus {
        background: $color-tracking-notice-accept-active;
      }
    }

    &--secondary {
      background: $color-tracking-notice-ignore;
      color: $color-tracking-notice-ignore-text;

      &:active,
      &:focus,
      &:hover {
        background-color: $color-tracking-notice-ignore-hover;
      }
    }
  }
}
