@mixin heading-1 {
  @include font-secondary;
  font-size: 2.4rem;
  font-weight: 700;
  line-height: 1.25;
}

@mixin heading-2 {
  @include font-secondary;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.33;
}

@mixin heading-3 {
  @include font-secondary;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1.4;
}
