$brand-color-primary-1-light: #053;
$brand-color-primary-1: #22382D;
$brand-color-primary-1-dark: #22382D;
$brand-color-primary-2-light: #0F774E;
$brand-color-primary-2: #22382D;
$brand-color-primary-2-dark: #22382D;
$brand-color-secondary-1-light: #EEF4F6;
$brand-color-secondary-1: #E4E6E9;
$brand-color-secondary-1-dark: #67696B;
$base-color-1: #22382D;
$base-color-2: #FFF;
$accent-color-1: #CA0C22;
$accent-color-2: #00683F;
