.asset-detail-properties {
  @include flex-container($flex-direction: column);
  border-bottom: 1px solid $color-asset-detail-properties-border;
  padding-bottom: $grid-gutter-size;

  &__row {
    @include flex-container();
  }

  &__column-name {
    color: $color-asset-detail-properties-font-property-name;
    margin-right: 2.4rem;
    width: 43%;
  }

  &__column-value {
    color: $color-asset-detail-properties-font-property-value;
    width: 57%;
  }

  &__extension {
    text-transform: uppercase;
  }
}

