@font-face {
  font-family: 'Krombacher Serif';
  src: local('Krombacher Serif'),
  url('../fonts/krombacher/serif/Krombacher-SerifRegular.otf') format('otf'),
  url('../fonts/krombacher/serif/Krombacher-SerifRegular.woff') format('woff'),
  url('../fonts/krombacher/serif/Krombacher-SerifRegular.woff2') format('woff2');
}
@font-face {
  font-family: 'Krombacher Serif';
  font-style: italic;
  src: url('../fonts/krombacher/serif/Krombacher-SerifRegularItalic.otf') format('otf'),
  url('../fonts/krombacher/serif/Krombacher-SerifRegularItalic.woff') format('woff'),
  url('../fonts/krombacher/serif/Krombacher-SerifRegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Krombacher Serif';
  font-weight: 700;
  src: url('../fonts/krombacher/serif/Krombacher-SerifDemi.otf') format('otf'),
  url('../fonts/krombacher/serif/Krombacher-SerifDemi.woff') format('woff'),
  url('../fonts/krombacher/serif/Krombacher-SerifDemi.woff2') format('woff2');
}
@font-face {
  font-family: 'Krombacher Serif';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/krombacher/serif/Krombacher-SerifDemiItalic.otf') format('otf'),
  url('../fonts/krombacher/serif/Krombacher-SerifDemiItalic.woff') format('woff'),
  url('../fonts/krombacher/serif/Krombacher-SerifDemiItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Krombacher Sans';
  src: url('../fonts/krombacher/sans/Krombacher-SansRegular.otf') format('otf'),
  url('../fonts/krombacher/sans/Krombacher-SansRegular.woff') format('woff'),
  url('../fonts/krombacher/sans/Krombacher-SansRegular.woff2') format('woff2');
}
@font-face {
  font-family: 'Krombacher Sans';
  font-style: italic;
  src: url('../fonts/krombacher/sans/Krombacher-SansRegularItalic.otf') format('otf'),
  url('../fonts/krombacher/sans/Krombacher-SansRegularItalic.woff') format('woff'),
  url('../fonts/krombacher/sans/Krombacher-SansRegularItalic.woff2') format('woff2');
}
@font-face {
  font-family: 'Krombacher Sans';
  font-weight: 700;
  src: url('../fonts/krombacher/sans/Krombacher-SansDemi.otf') format('otf'),
  url('../fonts/krombacher/sans/Krombacher-SansDemi.woff') format('woff'),
  url('../fonts/krombacher/sans/Krombacher-SansDemi.woff2') format('woff2');
}
@font-face {
  font-family: 'Krombacher Sans';
  font-style: italic;
  font-weight: 700;
  src: url('../fonts/krombacher/sans/Krombacher-SansDemiItalic.otf') format('otf'),
  url('../fonts/krombacher/sans/Krombacher-SansDemiItalic.woff') format('woff'),
  url('../fonts/krombacher/sans/Krombacher-SansDemiItalic.woff2') format('woff2');
}

@mixin font-primary {
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
  font-family: 'Krombacher Sans', 'Georgia', serif;
}

@mixin font-secondary {
  -moz-osx-font-smoothing: grayscale; // sass-lint:disable-line no-vendor-prefixes
  -webkit-font-smoothing: antialiased; // sass-lint:disable-line no-vendor-prefixes
  font-family: 'Krombacher Serif', 'Georgia', serif;
}

@mixin font-set(
  $size: null,
  $line-height: null,
  $weight: null
) {
  font-size: $size;
  font-weight: $weight;
  line-height: $line-height;
}
