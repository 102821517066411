.menu-nav-link {
  align-items: center;
  display: flex;
  hyphens: auto;
  padding: .3rem 0;
  word-break: break-word;

  &:hover,
  &:focus {
    color: #000;
  }

  &.active {
    .menu-nav-link__text {
      background-color: $color-sidebar-menu-list-item-active;
      border-left: 0.8rem solid $color-sidebar-menu-list-item-active;
      border-radius: 0.3rem;
      border-right: 0.8rem solid $color-sidebar-menu-list-item-active;

    }
  }
}
