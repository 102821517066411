.asset-tile {
  align-items: center;
  border: 1px solid $color-asset-tile-border;
  border-radius: 6px;
  box-shadow: inset 0 0 0 1px $color-asset-tile-border-box-shadow;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;

  @include breakpoint($breakpoint-s) {
    &--active,
    &:hover {
      background-color: $color-asset-tile-hover;
      border-color: $color-asset-tile-border-active;
      box-shadow: inset 0 0 0 1px $color-asset-tile-border-box-shadow-active;
      cursor: pointer;

    }
  }


  &__upper-section {
    margin-top: .4rem;
    position: relative;
    width: calc(100% - .8rem);

  }

  &__lower-section {
    padding: .5rem .8rem;
    position: relative;
    width: 100%;
  }


  &__image {
    height: auto;
    width: 100%;
  }

  &__name {
    @include font-asset-tile-name;
    color: $color-asset-tile-name;
    word-break: break-all;
  }

  &__properties {
    @include font-asset-tile-properties;
    color: $color-asset-tile-properties;

    &-extension {
      text-transform: uppercase;
    }

    &-dimension {
      display: block;
    }
  }
}

