// variables
$margin-bottom-asset-detail-view-image: $grid-gutter-size; // page padding  + margin-bottom img
$height-asset-detail-view-asset-navigation: 4rem + ($grid-gutter-size); // height of biggest icon + margin-bottom
$height-asset-detail-view-asset-navigation-desktop: 4rem + ($grid-gutter-size-s * 3); // height of biggest icon + margin-bottom
$height-asset-detail-view-title: (1.8rem * 1.33) + $grid-gutter-size; //  font-size * line-height (--> font-asset-detail-title) + margin-bottom
$height-asset-detail-view-title-desktop: (2.4rem * 1.5) + ($grid-gutter-size-s * 3); //  font-size * line-height (--> font-asset-detail-title) + margin-bottom
$height-asset-detail-view-category: (1.4rem * 1.71) + ($grid-gutter-size); // font-size * line-height (--> font-asset-detail-category) * margin-bottom
$height-asset-detail-view-category-desktop: (1.4rem * 1.71) + ($grid-gutter-size-s * 3); // font-size * line-height (--> font-asset-detail-category) * margin-bottom


.asset-detail-view {
  max-width: 100vw; // needed to truncate title if too long

  &__asset-navigation {
    @include flex-container($justify-content: space-between, $align-items: center);
    @include spacing(m, bottom, margin);

    &-link {
      @include flex-container($align-items: center);
      @include font-asset-detail-back-link;
      color: $color-asset-detail-view-font-back-link;

      &:visited {
        color: $color-asset-detail-view-font-back-link;
      }

      &:hover,
      &:focus {
        color: $color-asset-detail-view-font-back-link-hover;
        outline: none;

        &:before {
          background-color: $color-asset-detail-view-icon-button-mask-back-link-hover;

        }
      }

      &:before {
        background-color: $color-asset-detail-view-icon-button-mask-back-link;
        content: '';
        display: block;
        height: 3.2rem;
        mask: url('../img/icons/icon-arrow-short-right.svg') no-repeat center / cover;
        transform: rotateY(180deg) translate(34%);
        width: 3.2rem;
      }
    }

    &-button {
      &-container {
        @include flex-container($align-items: center);
      }

      background-color: $color-asset-detail-view-icon-button-mask-back;
      border: 0;
      color: transparent;
      font-size: 0;

      &:hover,
      &:focus {
        cursor: pointer;
        outline-color: $color-asset-detail-view-icon-button-mask-back;


      }

      &-prev {
        background: url('../img/icons/icon-arrow-short-right.svg') no-repeat center center / cover;
        height: 4rem;
        margin-right: $grid-gutter-size-s / 2;
        transform: rotateY(180deg);
        width: 4rem;

        &:hover,
        &focus {
          background: url('../img/icons/icon-arrow-short-right-hover.svg') no-repeat center center / cover;
        }
      }

      &-next {
        background: url('../img/icons/icon-arrow-short-right.svg') no-repeat center center / cover;
        height: 4rem;
        margin-left: $grid-gutter-size-s / 2;
        width: 4rem;

        &:hover,
        &:focus {
          background: url('../img/icons/icon-arrow-short-right-hover.svg') no-repeat center center / cover;
        }
      }

      &-close {
        background: url('../img/icons/icon-close-round.svg') no-repeat center center / cover;
        height: 3.2rem;
        margin-left: $grid-gutter-size-s / 2;
        width: 3.2rem;

        &:hover,
        &:focus {
          background: url('../img/icons/icon-close-round-hover.svg') no-repeat center center / cover;
        }
      }
    }
  }

  &__title {
    @include font-asset-detail-title;
    color: $color-asset-detail-view-font-title;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @include breakpoint($breakpoint-l) {
      @include font-asset-detail-title-desktop;
    }
  }

  &__category {
    @include flex-container($align-items: center);
    @include font-asset-detail-category;
    color: $color-asset-detail-view-font-category;
    margin-bottom: $grid-gutter-size;

    @include breakpoint($breakpoint-l) {
      margin-bottom: $grid-gutter-size-s * 3;
    }

    &:visited {
      color: $color-asset-detail-view-font-category;
    }

    &:before {
      background-color: $color-asset-detail-view-icon-button-mask-category;
      content: '';
      display: inline-block;
      flex-shrink: 0;
      height: 1.6rem;
      margin-right: $grid-gutter-size-s;
      mask: url('../img/icons/icon-folder.svg') no-repeat center / cover;
      width: 2rem;
    }

    &:hover {
      color: $color-asset-detail-view-font-category-hover;

      &:before {
        background-color: $color-asset-detail-view-icon-button-mask-category-hover;
      }
    }

    &:focus {
      outline: none;
    }

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  &__wrapper {
    @include flex-container($flex-wrap: wrap);

    @include breakpoint($breakpoint-l) {
      @include flex-container($justify-content: space-between);
    }

    .asset-detail__image {

      img {
        height: calc(100vh - #{$header-height-s} - #{$margin-bottom-asset-detail-view-image} - #{$height-asset-detail-view-asset-navigation} - #{$height-asset-detail-view-title} - #{$height-asset-detail-view-category});
      }

      @include breakpoint($breakpoint-l) {
        margin-right: $grid-gutter-size-s * 5;
        max-width: 70%;

        img {
          height: calc(100vh - #{$header-height-l} - #{$margin-bottom-asset-detail-view-image} - #{$height-asset-detail-view-asset-navigation-desktop} - #{$height-asset-detail-view-title-desktop} - #{$height-asset-detail-view-category-desktop});
        }
      }
    }
  }

  &__data {
    width: 100%;

    @include breakpoint($breakpoint-l) {
      max-width: 48rem;
      width: 32.3%;
    }

    .asset-detail__button {
      @include icon-button($mobile-size: 4rem);
    }
  }

  &__buttons {
    margin: 1.6rem auto;
    text-align: center;
    width: 100%;
  }
}
