.sub-category {

  &-list-element {
    border-bottom: 0.1rem solid $color-sub-category-link-border;
  }

  &-link {
    @include font-sub-category-link();

    align-items: center;
    color: $color-sub-category-link-text;
    display: flex;
    padding: $grid-gutter-size 0;
    position: relative;
    width: 100%;

    &-text {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    &:hover {
      color: $color-sub-category-link-text-hover;

      &:before {
        background-color: $color-sub-category-link-folder-hover;
      }

      &:after {
        background-color: $color-sub-category-link-arrow-right;
        content: "";
        height: 3.2rem;
        mask: url('../img/icons/icon-arrow-short-right.svg') no-repeat center / cover;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%); //needed for safari
        width: 3.2rem;
      }
    }

    &:before {
      background-color: $color-sub-category-link-folder;
      content: '';
      display: inline-block;
      flex-shrink: 0;
      height: 3rem;
      margin-right: $grid-gutter-size-s;
      mask: url('../img/icons/icon-folder.svg') no-repeat center / cover;
      width: 4rem;
    }
  }
}


