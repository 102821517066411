.breadcrumb {
  @include spacing(s, bottom, margin);
  @include flex-container();
  height: 2.4rem;
  max-width: calc(100vw - #{$grid-gutter-size} * 2); // subtract margins left and right

  @include breakpoint($breakpoint-m) {
    @include flex-container($flex-wrap: wrap);
    height: auto;
  }

  &__link {
    @include flex-container();
    @include font-breadcrumb;
    color: $brand-color-primary-1;
    display: none;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    &:last-of-type {
      display: flex;
    }

    @include breakpoint($breakpoint-l) {
      $page-template-sidebar-menu-width: 29rem;
      max-width: calc(100vw - #{$page-template-sidebar-menu-width} - #{$page-template-asset-detail-aside-width}); // to prevent content from breaking out
    }

    &:visited,
    &:hover {
      color: $brand-color-primary-1
    }

    &:not(:last-of-type) {
      &:after {
        align-self: center;
        background-color: $color-icon-breadcrumb;
        content: '';
        display: block;
        height: 2rem;
        margin: 0 .3rem;
        mask: url('../img/icons/icon-arrow-short-right.svg') no-repeat center / cover;
        width: 2rem;
      }
    }

    &:last-of-type {
      &:before {
        align-self: center;
        background-color: $color-icon-breadcrumb;
        content: '';
        display: block;
        height: 2rem;
        margin: 0 .3rem;
        mask: url('../img/icons/icon-arrow-short-right.svg') no-repeat center / cover;
        transform: rotateY(180deg);
        width: 2rem;
      }
    }

    &--home {
      &:last-of-type {
        &:before {
          display: none;
        }
      }
    }

    @include breakpoint($breakpoint-m) {
      @include flex-container();

      &:last-of-type {
        &:before {
          display: none;
        }
      }
    }
  }
}
