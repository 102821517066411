.asset-cart-nav-link {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;

  @include breakpoint($breakpoint-l) {
    align-items: center;
    border: 0;
    border-radius: 0;
    height: 5.6rem;
    justify-content: center;
    width: 15.2rem;

    &:hover,
    &:focus {
      .asset-cart-nav-link__number {
        border-color: $color-asset-cart-nav-link-background-hover-and-active;
        color: $color-asset-cart-nav-link-background-hover-and-active;

        &-container {
          background-color:  $color-asset-cart-nav-link-background-hover-and-active;
        }
      }

      .asset-cart-nav-link__title {
        color: $color-asset-cart-nav-link-background-hover-and-active;
      }
    }
  }

  &--active {
    cursor: default;

    .asset-cart-nav-link__number {
      border-color: $color-asset-cart-nav-link-background-hover-and-active;
      color: $color-asset-cart-nav-link-background-hover-and-active;

      &-container {
        background-color:  $color-asset-cart-nav-link-background-hover-and-active;
      }
    }

    .asset-cart-nav-link__title {
      color: $color-asset-cart-nav-link-background-hover-and-active;
    }
  }

  &__title {
    color: transparent;
    font-size: 0;

    @include breakpoint($breakpoint-l) {
      @include font-asset-cart-nav-link-title;

      color: $color-asset-cart-nav-link-title;
      padding-right: $grid-gutter-size-s;
    }
  }

  &__number {
    @include font-asset-cart-nav-link-number;
    align-items: center;
    background-color: $color-asset-cart-nav-link-number-background;
    border: solid .1rem $color-asset-cart-nav-link-background;
    border-radius: 100%;
    color: $color-asset-cart-nav-link-background;
    display: flex;
    height: 2.5rem;
    justify-content: center;
    left: 1.7rem;
    position: absolute;
    top: -.5rem;
    width: 2.5rem;

    @include breakpoint($breakpoint-l) {
      left: 2.2rem;
      top: -.8rem;
    }

    &-container {
      background-color: $color-asset-cart-nav-link-background;
      border-radius: 100%;
      height: 3.2rem;
      position: relative;
      width: 3.2rem;

      &:before {
        background-color: $color-asset-cart-nav-link-icon;
        content: '';
        display: block;
        height: 1.9rem;
        left: 50%;
        mask: url('../img/icons/AssetCart.svg') no-repeat center / cover;
        position: absolute;
        top: 50%;
        transform: translate(-50%,-50%);
        width: 1.9rem; // had to add .1rem, otherwise icon will be cut off
      }
    }
  }
}
