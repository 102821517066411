.asset-detail-information {
  @include breakpoint($breakpoint-l) { // remove breakpoint mixin once we have the related content so that border is also visible on mobile
    border-bottom: 1px solid $color-asset-detail-information-border;
  }

  &__tab-header {
    @include font-asset-detail-information-tab-header;
    @include spacing(m, bottom, margin);

    border-bottom: .4rem solid $color-asset-detail-information-header-border;
    font-weight: 700;
  }

  &__tab-data {
    color: $color-asset-detail-information-font-tab-data;
  }

  &__description {
    @include spacing(m, bottom, padding);
  }

  &__additional-properties {
    @include spacing(m, bottom, padding);

    &-name {
      font-weight: 700;
    }

    &-name, &-value {
      display: block;
    }
  }
}
