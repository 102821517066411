// the idea of the template is to visualise the components according to concept
// each component should be independent from template

$page-template-sidebar-menu-width: 29rem;
$page-template-main-height: calc(100vh - #{$header-height-s});
$page-template-main-height-l: calc(100vh - #{$header-height-l});
$page-template-navigated-wrapper-width: calc(100vw  + #{$page-template-sidebar-menu-width});
$page-template-border-style: .1rem solid $color-page-template-border;

@mixin page-template-show-menu {
  width: $page-template-navigated-wrapper-width;
}

@mixin page-template-hide-menu {
  transform: translateX(-$page-template-sidebar-menu-width);
  width: $page-template-navigated-wrapper-width;
}

@keyframes page-template-wrapper-move-in-left {
  0% {
    transform: translateX(-$page-template-sidebar-menu-width);
  }

  100% {
    transform: translate(0);
  }
}

@keyframes page-template-wrapper-move-in-right {
  0% {
    transform: translate(0);
  }

  100% {
    transform: translateX(-$page-template-sidebar-menu-width);
  }
}

.page-template {
  overflow: hidden; // to prevent content from breaking out on smaller screens when menu is open

  &__header {
    border-bottom: $page-template-border-style;
    box-shadow: -.5rem 0 1.5rem 0 $color-page-template-border;

    @include breakpoint($breakpoint-l) {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: 1;
    }
  }

  &__wrapper {
    align-items: flex-start;
    display: flex;
    min-height: $page-template-main-height;

    @include breakpoint($breakpoint-l) {
      min-height: $page-template-main-height-l;
    }

    &--show-menu {
      @include page-template-show-menu;
    }

    &--show-menu-animated {
      @include page-template-show-menu;

      animation: page-template-wrapper-move-in-left .5s;
    }

    &--hide-menu {
      @include page-template-hide-menu;
      max-height: calc(100vh - 6.4rem);
    }

    &--hide-menu-animated {
      @include page-template-hide-menu;

      animation: page-template-wrapper-move-in-right .5s;
      max-height: calc(100vh - 6.4rem);
    }

    @include breakpoint($breakpoint-l) {
      margin-top: $header-height-l;
      position: initial;
      transform: translateX(0);
      width: 100vw;
    }
  }

  &__sidebar-menu {
    background-color: $color-page-template-background;
    border-right: $page-template-border-style;

    @include breakpoint($breakpoint-l) {
      @include define-scrolling;
      min-height: $page-template-main-height-l;
    }

    display: block;
    flex: 0 0 $page-template-sidebar-menu-width;
    max-height: $page-template-main-height;
    overflow-y:  scroll;
    position: initial;
  }

  // this extra wrapper is used to implement click behavior in mobile
  &__main {
    @include font-page-template-error;
    flex-grow: 1; // occupy the rest of the space and make main responsive

    &-content {
      @include define-scrolling;

      display: flex;
      flex-direction: column; // it is a preparetion for the position of the footer
      max-width: 100vw;



      @include breakpoint($breakpoint-l) {
        background-color: $color-page-template-background;
        filter: none;
        pointer-events: auto;

        &--S {
          $page-template-sidebar-menu-width: 29rem;
          max-width: calc(100vw - #{$page-template-sidebar-menu-width} - #{$page-template-asset-detail-aside-width}); // to prevent content from breaking out
        }
      }
    }
  }

  &__organism-container {
    @include spacing(m, all, margin);

    h1 {
      hyphens: auto;
      word-break: break-word;
    }
  }

  &__footer {
    align-items: flex-end; // stick the footer at the end of the view
    display: flex;
    flex-basis: 100%; // fill the rest of the space in the view
    min-height: 7rem;
  }

  &__asset-detail-aside {
    display: none;
    @include breakpoint($breakpoint-l) {
      @include define-scrolling;

      border-left: $page-template-border-style;
      display: block;
      flex: 0 0 $page-template-asset-detail-aside-width;
    }
  }
}
