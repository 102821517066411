.asset-tiles {
  // breakpoints for asset tiles only (!)
  $asset-tiles-breakpoint-phone-s: 392px;
  $asset-tiles-breakpoint-phone-m: 580px;
  $asset-tiles-breakpoint-tablet-s: 767px;
  $asset-tiles-breakpoint-tablet-m: 956px;
  $asset-tiles-breakpoint-desktop-xs: 1144px;
  $asset-tiles-breakpoint-desktop-s: 1200px;
  $asset-tiles-breakpoint-desktop-m: 1216px;
  $asset-tiles-breakpoint-desktop-l: 1404px;
  $asset-tiles-breakpoint-desktop-xl: 1592px;
  $asset-tiles-breakpoint-desktop-xxl: 1779px;

  @include spacing(m, top, margin);

  &__list {
    @include flex-container($flex-wrap: wrap);
    list-style: none;
    margin: -$grid-gutter-size / 2;
  }

  &__list-element {
    border-radius: .6rem;
    min-width: 18.8rem;
    overflow: hidden;
    padding: $grid-gutter-size / 2;
    width: 100%;

    @include breakpoint($asset-tiles-breakpoint-phone-s) {
      width: calc(100% / 2);
    }

    @include breakpoint($asset-tiles-breakpoint-phone-m) {
      width: calc(100% / 3);
    }

    @include breakpoint($asset-tiles-breakpoint-tablet-s) {
      width: calc(100% / 4);
    }

    @include breakpoint($asset-tiles-breakpoint-tablet-m) {
      width: calc(100% / 5);
    }

    @include breakpoint($asset-tiles-breakpoint-desktop-xs) {
      width: calc(100% / 6);
    }

    @include breakpoint($asset-tiles-breakpoint-desktop-s) {
      width: calc(100% / 2);
    }

    @include breakpoint($asset-tiles-breakpoint-desktop-m) {
      width: calc(100% / 3);
    }

    @include breakpoint($asset-tiles-breakpoint-desktop-l) {
      width: calc(100% / 4);
    }

    @include breakpoint($asset-tiles-breakpoint-desktop-xl) {
      width: calc(100% / 5);
    }

    @include breakpoint($asset-tiles-breakpoint-desktop-xxl) {
      width: calc(100% / 6);
    }

    &:hover {
      border: 0;
    }
  }
}
