.asset-detail {
  &__image {
    @include flex-container($flex-direction: column, $justify-content: center, $align-items: center, $align-content: center);
    border: 1px solid $color-asset-detail-image-border;
    margin-bottom: $grid-gutter-size;
    width: 100%;

    img {
      max-height: 100%;
      max-width: 100%;
      object-fit: contain;
      object-position: 50% 50%;
    }
  }
}
