.header {
  @include spacing(s, all, padding);
  @include flex-container($align-items: center, $flex-wrap: wrap, $justify-content: flex-start);

  background-color: $color-header-background;
  background-image: url('https://cdn.krombacher.de/krombacher-bootstrap-theme@3.0.3/dist/images/background-reloaded-hres.webp');
  background-size: cover;

  height: $header-height-s;
  position: relative;

  & > .menu-button,
  & > .logo {
    margin-right: $grid-gutter-size-s;
  }

  & > .logo { // BasToDo this is only temporary for the header without a search bar since there isn't enough space
    // to fit in menu, logos and asset cart nav link
    width: 21.5rem;

    @include breakpoint(330px) {
      width: auto;
    }
  }

  & > .asset-cart-nav-link {
    position: absolute;
    right: $grid-gutter-size;
  }

  @include breakpoint($breakpoint-l) {
    @include spacing(s, vertical, padding);
    @include spacing(m, horizontal, padding);
    @include flex-container($align-items: center, $flex-wrap: wrap, $justify-content: space-between);

    height: $header-height-l; // set fixed header height for layout

    & > .menu-button,
    & > .logo {
      margin-right: 0;
    }
  }
}
