.home-nav-link {
  @include spacing(s, bottom, margin);
  align-items: center;
  display: flex;

  &__text {
    @include font-sidebar-menu-nav-link-main;
    margin-left: $grid-gutter-size-s;
  }
}
