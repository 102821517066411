
// we are using mobile-first approach
// usecase of this mixin:
// @include breakpoint($breakpoint-l) {
//   border-left: 1px solid $brand-color-secondary-1;
//   display: block;
// }
@mixin breakpoint($breakpoint) {
  @media only screen and (min-width: $breakpoint) {
    @content
  };
}

// PageTemplate
@mixin define-scrolling {
  height: $page-template-main-height;
  overflow: auto;

  @include breakpoint($breakpoint-l) {
    height: $page-template-main-height-l;
  }
}

@mixin flex-container($flex-direction: row, $flex-wrap: nowrap, $justify-content: flex-start, $align-items: stretch, $align-content: stretch) {
  // this is to be used on the flex-container (parent), NOT the flex-item(s) inside (children)
  // (for an explanation, see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
  align-content: $align-content;
  align-items: $align-items;
  display: flex;
  flex-direction: $flex-direction;
  flex-wrap: $flex-wrap;
  justify-content: $justify-content;
}

@mixin flex-item($order: 0, $flex-grow: 0, $flex-shrink: 1, $flex-basis: auto) {
  // this is to be used on the flex-item(s) (children), NOT the flex container (parent)
  // (for an explanation, see https://css-tricks.com/snippets/css/a-guide-to-flexbox/)
  // pls note: If you do need to use align-self, set it individually on the respective flex-item as needed.
  // It will not be include this mixin, since it is supposed to be used to overwrite the default alignment for a
  // specific item only.
  flex-basis: $flex-basis;
  flex-grow: $flex-grow;
  flex-shrink: $flex-shrink;
  order: $order;
}

@mixin icon-button($mobile-size: 5rem, $desktop-size: 4rem) {
  background-color: $color-icon-button-background;
  border: 0;
  border-radius: 100%;
  display: inline-block;
  height: $mobile-size;
  margin-right: 1rem;
  margin-top: .5rem;
  width: $mobile-size;

  @include breakpoint($breakpoint-l) {
    height: $desktop-size;
    width: $desktop-size;
  }

  &:focus {
    outline: 0;
  }

  &:hover {
    cursor: pointer;

    &:before {
      background-color: $color-icon-button-mask-hover;
    }
  }

  &:before {
    background-color: $color-icon-button-mask;
    content: '';
    display: block;
    height: 100%;
    width: 100%;
  }
}

@mixin icon-button-mask($mask-url: '../img/icons/addToAssetCart.svg') {
  &:before {
    mask: url($mask-url) no-repeat 50% 50% / 100% 100%;
  }
}

// Buttons for Tracking notice copied from krombacher website
@mixin button-rectangle {
  @include font-tracking-notice-button-label;
  cursor: pointer;
  display: inline-block;
  height: 48px;
  line-height: 48px;
  outline: 0;
  overflow: hidden;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;

  &:hover,
  &:active,
  &:focus,
  &:disabled {
    text-decoration: none;
  }

  &:disabled {
    cursor: not-allowed;
  }
}

@mixin button-wide {
  @include button-rectangle ;
  border-radius: 2px;
  max-width: 100%;
  min-width: 200px;
  padding: 0 40px;

  @include breakpoint(500px) {
    max-width: 440px;
  }
}
