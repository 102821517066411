// breakpoints:
// 320px - 640px:  Phone, 320px is supported minimum
// 640 - 900px:    Tablet portrait
// 900 - 1200px:   Tablet landscape
// 1200px + :      Desktop
$breakpoint-s: 640px;
$breakpoint-m: 900px;
$breakpoint-l: 1200px;

// Header
$header-height-s: 6.4rem;
// BasToDo: ADD $header-height-m once we have a search bar! Don't forget to refactor variables in PageTemplate!
$header-height-l: 12rem;

// Footer
$footer-height: 3.2rem;

// grid gutters
$grid-gutter-size: 1.6rem;
$grid-gutter-size-s: .8rem;

// page templating variables
$page-template-asset-detail-aside-width: 33rem;


// ******************* (mobile, tablet, desktop) GRID SPACING MATRIX *******************
//usage
// @include spacing(s, all, margin);
// @include spacing(m, right, padding);
// @include spacing(l, vertical, margin);
// @include spacing(l, horizontal, margin);


$spacing-s: (.8rem, .8rem, 1.6rem);
$spacing-m: (1.6rem, 1.6rem, 2.4rem);
$spacing-l: (2.4rem, 2.4rem, 4rem);

@mixin _margin($px, $direction) {
  @if($direction == top or $direction == horizontal or $direction == all) {
    margin-top: $px;
  }

  @if($direction == bottom or $direction == horizontal or $direction == all) {
    margin-bottom: $px;
  }

  @if($direction == right or $direction == vertical or $direction == all) {
    margin-right: $px;
  }

  @if($direction == left or $direction == vertical or $direction == all) {
    margin-left: $px;
  }
}

@mixin _padding($px, $direction) {
  @if($direction == top or $direction == horizontal or $direction == all) {
    padding-top: $px;
  }

  @if($direction == bottom or $direction == horizontal or $direction == all) {
    padding-bottom: $px;
  }

  @if($direction == right or $direction == vertical or $direction == all) {
    padding-right: $px;
  }

  @if($direction == left or $direction == vertical or $direction == all) {
    padding-left: $px;
  }
}

@mixin spacing($size, $direction, $type) {
  $size-list: (0, 0, 0);
  @if($size == s) {
    $size-list: $spacing-s;
  } @else if ($size == m) {
    $size-list: $spacing-m;
  } @else if ($size == l) {
    $size-list: $spacing-l;
  }

  @if($type) == margin {
    @include _margin(nth($size-list, 1), $direction);

    @include breakpoint($breakpoint-s) {
      @include _margin(nth($size-list, 2), $direction);
    }

    @include breakpoint($breakpoint-m) {
      @include _margin(nth($size-list, 2), $direction);
    }

    @include breakpoint($breakpoint-l) {
      @include _margin(nth($size-list, 3), $direction);
    }
  } @else if($type == padding) {
    @include _padding(nth($size-list, 1), $direction);

    @include breakpoint($breakpoint-s) {
      @include _padding(nth($size-list, 2), $direction);
    }

    @include breakpoint($breakpoint-m) {
      @include _padding(nth($size-list, 2), $direction);
    }

    @include breakpoint($breakpoint-l) {
      @include _padding(nth($size-list, 3), $direction);
    }
  }
}
