.footer {
  @include spacing(m, vertical, padding);
  @include font-footer;
  @include breakpoint($breakpoint-s) {
    flex-direction: unset;
    height: $footer-height;
  }
  align-items: center;
  border-top: 1px solid $color-footer-border-top;
  display: flex;
  flex-direction: column;
  height: auto;
  justify-content: space-between;
  width: 100%;

  &__links {
    color: $color-footer-links;
  }

  &__link {
    &:hover, &:focus {
      color: $color-footer-link-hover;
      cursor: pointer;
      outline: none;
    }
  }

  &__copyright {
    color: $color-footer-copyright;
  }
}
