.introduction {
  @include spacing(l, bottom, margin);

  &__headline {
    @include spacing(s, bottom, margin);
    @include font-introduction-headline;
  }

  &__text {
    @include font-introduction-text;
    color: $color-introduction-text;
  }
}
