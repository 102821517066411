ul,
ol,
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

// reset all margin, padding and box-sizing
*,
*::after,
*::before {
  box-sizing: inherit;
  margin: 0;
  padding: 0;
}

* {
  -webkit-overflow-scrolling: touch; // sass-lint:disable-line no-vendor-prefixes
}

html {
  font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
}

body {
  box-sizing: border-box;

  @include breakpoint($breakpoint-l) {
    overflow: hidden;
  }

  overflow-x: hidden;
}

%link-styles {
  color: inherit;
  text-decoration: none;
  //transition: color .5s;
}

a {
  @extend %link-styles;

  &:visited {
    @extend %link-styles;
  }

  //BasTodo: commented out for now so the linter won't complain.
  //&:hover {
  //color: $color-nav-link-hover;
  //}
}

